<template>
  <t-button :variant="variant"
         :size="size"
         :class="[btnClasses, {'hover-icon': hoverIcon, 'hover-text': hoverText}]"
         :style="btnStyle"
         @dragstart="$emit('dragstart', $event)"
         @dragend="$emit('dragend', $event)"
         @click.stop.prevent="copyToClipboard()">
    <fluency-icon v-if="icon && iconBefore"
                  type="copy"
                  :class="iconBeforeClass" />
    <slot name="default"></slot>
    <span v-if="hoverText"
          class="text-link"
          :class="[textClasses]">{{text}}</span>
    <template v-else>{{text}}</template>
    <fluency-icon v-if="icon && !iconBefore"
                  type="copy"
                  class="ml-3" />
  </t-button>
</template>

<script>
export default {
  name: 'copyToClipboard',
  props: {
    val: {
      type: String,
      default: ''
    },
    variant: {
      type: String,
      default: 'link'
    },
    size: {
      type: String,
      default: 'md'
    },
    btnClasses: {
      type: String,
      default: null
    },
    btnStyle: {
      type: String,
      default: null
    },
    icon: {
      type: Boolean,
      default: true
    },
    iconBefore: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: ''
    },
    textClasses: {
      type: String,
      default: null
    },
    hoverIcon: {
      type: Boolean,
      default: false
    },
    hoverText: {
      type: Boolean,
      default: false
    },
    customToast: {
      type: String,
      default: null
    },
    iconBeforeClass: {
      type: String,
      default: 'mr-2'
    }
  },
  methods: {
    copyToClipboard: function () {
      try {
        navigator.clipboard.writeText(this.val)

        const toastVal = (this.customToast) ? this.customToast : `'${this.val}'`

        this.$toast(`<b>${toastVal}</b> has been copied to your device clipboard`, 'success')
      } catch (err) {
        this.$toast('Sorry, this browser doesn\'t seem to support copying to the clipboard', 'warning')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .hover-text .text-link,
  .hover-icon .fluency-icon {
    visibility: hidden;
  }
  .hover-text:hover .text-link,
  .hover-icon:hover .fluency-icon {
    visibility: visible;
  }
</style>
